import "./index.css";
import { useState } from "react";
import { Flex, Badge } from "antd";
import { useNavigate } from "react-router-dom";
import { pathData } from "../../../../../utils/routes";
import { colors } from "../../../../../utils/service";
import { useAuth } from "../../../../../components/contexts/AuthContext";
import { follow } from "../../../../../services/supabaseClient";
import useWindowSize from "../../../../../components/hooks/useWindowSize";

const Reviewer = ({ data, ...props }) => {
  const { isMobileView } = useWindowSize();
  const navigate = useNavigate();
  const [reviewer, setReviewer] = useState(data);
  const { user, openLoginModal } = useAuth();

  const avtSize = isMobileView ? "4rem" : "5rem";

  const onFollow = (e) => {
    e.stopPropagation();
    if (!user) {
      openLoginModal();
      return;
    }

    const payload = {
      followee_id: reviewer.id,
    };

    setReviewer((prev) => {
      return {
        ...prev,
        followed: true,
      };
    });
    follow(payload);
  };

  return (
    <Flex style={{ width: isMobileView ? "40vw" : "12vw" }} {...props} vertical gap={0} align="center">
      <div
        onClick={() => navigate(`${pathData.profile}/${reviewer.id}`)}
        style={{
          width: avtSize,
          height: avtSize,
          borderRadius: "50%",
          boxShadow:
            "0 3px 8px 0 rgba(0, 0, 0, 0.20), 0 3px 8px -4px rgba(0, 0, 0, 0.30), 0 5px 14px 4px rgba(0, 0, 0, 0.05)",
        }}>
        <img
          style={{ width: avtSize, height: avtSize, borderRadius: "50%", cursor: "pointer" }}
          alt=""
          src={reviewer.avatar}
        />
      </div>

      <h1
        onClick={() => navigate(`${pathData.profile}/${reviewer.id}`)}
        className="one-line-ellipsis"
        style={{ margin: "0.75rem 0 0 0", fontSize: "0.9rem", fontWeight: "600", cursor: "pointer" }}>
        {reviewer.name}
      </h1>

      <Flex gap={6} justify="center" align="center" style={{ width: "100%" }}>
        <Badge color="green" />
        <span
          className="vfra-top-reviewer-featured-post"
          style={{ color: colors.grayColor }}
          onClick={() => navigate(`${pathData.postDetail}/${reviewer.postId}`)}>
          {reviewer.totalNewPosts} featured post
        </span>
      </Flex>

      {reviewer.followed && (
        <div
          className="vfra-top-reviewer-tag"
          style={{
            background: colors.mainThemeColor,
            color: "#fff",
            boxShadow:
              "0 3px 8px 0 rgba(0, 0, 0, 0.20), 0 3px 8px -4px rgba(0, 0, 0, 0.30), 0 5px 14px 4px rgba(0, 0, 0, 0.05)",
          }}>
          Following
        </div>
      )}

      {!reviewer.followed && (
        <div
          className="vfra-top-reviewer-tag"
          style={{
            boxShadow:
              "0 3px 8px 0 rgba(0, 0, 0, 0.08), 0 3px 4px -2px rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.05)",
            cursor: "pointer",
          }}
          onClick={(e) => onFollow(e)}>
          Follow
        </div>
      )}
    </Flex>
  );
};

export default Reviewer;
