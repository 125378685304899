import "./index.css";
import { Skeleton, Row, Col } from "antd";
import useWindowSize from "../../../../components/hooks/useWindowSize";
import { useSearch } from "../../../../components/contexts/SearchContext";

const BrandsLoading = () => {
  const { isMobileView } = useWindowSize();
  const { showSearchMobile } = useSearch();
  return (
    <>
      {!showSearchMobile && <br></br>}
      <Row className="vfra-brands-loading-container" gutter={!isMobileView ? [32, 32] : [24, 24]}>
        {Array.from({ length: isMobileView ? 8 : 24 }).map((_, i) => (
          <Col key={i} span={!isMobileView ? 6 : 24}>
            <Skeleton.Node active={true} style={{ height: "70px" }} />
          </Col>
        ))}
      </Row>
    </>
  );
};

export default BrandsLoading;
