import { useState, useCallback, useEffect } from "react";
import { Flex, Row, Col, Button, Empty, message } from "antd";
import { search_perfumes } from "../../../services/meilisearchPerfumes";
import { useSearch } from "../../../components/contexts/SearchContext";
import useWindowSize from "../../../components/hooks/useWindowSize";
import PerfumeLoading from "./PerfumesLoading";
import PerfumeItem from "./PerfumeItem";

const SearchPerfumes = ({ searchValue }) => {
  const { isMobileView } = useWindowSize();
  const { showSearchMobile } = useSearch();

  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [perfumes, setPerfumes] = useState([]);
  const [total, setTotal] = useState(0);

  const onSearch = useCallback(async () => {
    try {
      setLoading(true);
      const pageSize = isMobileView ? 8 : 24;

      const searchResults = await search_perfumes(searchValue, page, pageSize);
      setTotal(searchResults.estimatedTotalHits);

      if (page > 1) {
        setPerfumes((prev) => [...prev, ...searchResults.hits]);
      } else {
        setPerfumes(searchResults.hits);
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      setLoading(false);
    }
  }, [searchValue, page, isMobileView]);

  useEffect(() => {
    if (searchValue) {
      onSearch();
    }
  }, [searchValue, onSearch]);

  const handleLoadMore = () => {
    setPage((prevPage) => prevPage + 1);
  };

  return (
    <>
      {loading && <PerfumeLoading />}

      {!showSearchMobile && <br></br>}

      {!loading && perfumes.length === 0 && (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={
            <Flex vertical align="center">
              <p style={{ fontWeight: "bold", fontSize: "0.9rem" }}>No perfumes found!</p>
            </Flex>
          }
        />
      )}

      {!loading && perfumes.length > 0 && (
        <Row gutter={!isMobileView ? [24, 24] : [12, 12]} justify="center" vertical="vertical">
          {perfumes.map((item) => {
            return (
              <Col key={item.id} span={!isMobileView ? 6 : 24}>
                <PerfumeItem item={item} />
              </Col>
            );
          })}
        </Row>
      )}

      {!loading && perfumes.length < total && (
        <Flex justify="center" style={{ margin: "1.5rem 0" }}>
          <Button shape="round" onClick={handleLoadMore} loading={loading} iconPosition="end">
            More
          </Button>
        </Flex>
      )}
    </>
  );
};

export default SearchPerfumes;
