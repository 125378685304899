import { useNavigate } from "react-router-dom";
import { Card, Flex, Badge } from "antd";
import { pathData } from "../../../utils/routes";
import { useSearch } from "../../../components/contexts/SearchContext";

const cardStyle = {
  margin: "12px",
  height: "5rem",
};

const BrandItem = ({ designer }) => {
  const navigate = useNavigate();

  const { setShowSearchMobile } = useSearch();
  const handleClick = () => {
    setShowSearchMobile(false);
    navigate(`${pathData.brand}/${designer.id}`);
  };

  return (
    <Card
      hoverable
      key={designer.id}
      style={cardStyle}
      bordered={false}
      onClick={handleClick}
      styles={{
        body: {
          padding: "0.5rem",
        },
      }}>
      <Flex justify="start" align="center">
        <img alt="" src={designer.logo_url} style={{ width: "3rem", marginRight: "0.75rem" }} />
        <span className="two-line-ellipsis" style={{ fontWeight: "700", fontSize: "0.8rem" }}>
          {designer.brand_name}
        </span>
        <div style={{ marginLeft: "auto" }}>
          <Badge
            count={designer.perfume_count}
            style={{
              backgroundColor: "rgb(229 229 229 / 37%)",
              color: "rgba(0, 0, 0, 0.88)",
              fontWeight: "700",
              fontSize: "0.7rem",
            }}
          />
        </div>
      </Flex>
    </Card>
  );
};

export default BrandItem;
